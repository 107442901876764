<template>
  <v-dialog
    v-model="dialog"
    width="500"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        small
        color="red lighten-1"
        class="mt-2"
        v-bind="attrs"
        v-on="on"
        >
        Deshabilitar cuenta
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        ¿Desea deshabilitar su cuenta?
      </v-card-title>

      <v-card-text>
        Su cuenta permanecerá inactiva hasta el momento en que vuelva a iniciar sesión, de manera que no será visible para otros usuarios ni aparecerá en recomendaciones / resultados de búsquedas.
      </v-card-text>

      <v-card-actions
        class="justify-end"
        >
        <v-btn
          text
          color="error"
          @click="disableAccount"
          >
          Deshabilitar
        </v-btn>

        <v-btn
          depressed
          color="primary"
          @click="dialog = false"
          >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DisableAccount } from '@/graphql/mutations/users'

export default {
  data: () => ({
    dialog: false,
  }),

  methods: {
    disableAccount () {
      this.$apollo.mutate({
        mutation: DisableAccount,
        variables: {
          input: {
          }
        }
      }).then ( res => {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('order_token')
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>
